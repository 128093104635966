import { Component, OnInit } from '@angular/core';
import { LayersConfigService } from '../../layers/layers.config.service';
import { LayerConfig } from '../../layers/layers.config'
import { Subscription } from 'rxjs';
import { ToolsService } from '../tools.service';
import { GeneralService } from 'src/app/service/general.service';
import { DownloadFormat } from 'src/app/map/vo/download-format';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ToastService } from 'src/app/service/toast.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-export-sinter-tool',
  templateUrl: './export-sinter-tool.component.html',
  styleUrls: ['./export-sinter-tool.component.css']
})
export class ExportSinterToolComponent implements OnInit {

  overlayers: LayerConfig[]=[];
  downloadTool:any;
  subscriptionTool:Subscription;  
  downloadFormat: string;
  formats: Array<DownloadFormat>;
  notificationSubscrition: Subscription;
  downloading: boolean = false;
  

  constructor(private toolsService: ToolsService,
    private generalService: GeneralService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private toastService: ToastService) 
  { 
    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      } 
  });
  }

  ngOnInit(): void 
  {
    this.init()
  }
  private init()
  {
   
  }

  public download()
  {
    this.downloading = true;

    let data = this.formatDate(new Date(), "yyyyMMddHHmm")
  
    let outputFilename=environment.CODIGO_IBGE_SINTER + "_"+ data +".zip";

    let url = environment.BACKEND_API_BASE_PATH+'/export/sinter';

    let user = this.authenticationService.getCurrentUser();
    if(!user)
    {
      this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao exportar pacote Sinter.");
    }
    else
    {
      this.generalService.downloadFileGetMethod(url, outputFilename, user.token);
    }
  }
  public transfer()
  {
    this.toastService.warning("Em desenvolvimento.", "Atenção.");
  }
  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }

}
