<div class="download-div" >
    <div class="download-layer">
        
        <div class="download-layer-button">
            <div class="download-button">
                <button *ngIf="downloading == false" type="button" (click)="download()" mat-raised-button color="primary" id="download-layer-button">
                    <span class="material-icons">get_app</span> Baixar
                </button>
                <button *ngIf="downloading == true" type="button" (click)="download()" mat-raised-button color="disabled" id="download-layer-button" disabled="disabled">
                    Baixando
                </button>                
            </div>
            <div  class="transfer-button">
                <button *ngIf="downloading == false" type="button" (click)="transfer()" mat-raised-button color="primary" id="download-layer-button">
                    <span class="material-icons">outbox</span> Transmitir
                </button>
                <button *ngIf="downloading == true" type="button" (click)="transfer()" mat-raised-button color="disabled" id="download-layer-button" disabled="disabled">
                    Transmitindo
                </button>                
            </div>
            <mat-spinner *ngIf="downloading == true" [diameter]="15" strokeWidth="2"></mat-spinner>  
        </div>    
</div>

