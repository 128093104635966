import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { ApiModule, BASE_PATH } from '@funcate/sigweb-cti-api';
import { environment } from '../environments/environment';

// material.module.ts
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomMaterialModule } from './material.module';
import { MatPaginatorLabel } from './mat-paginator-label';

import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';
import { CanvasComponent } from './map/canvas/canvas.component';
import { LayersComponent } from './map/layers/layers.component';
import { DialogComponent } from './map/dialog/dialog.component';

// Services declared as global to keep as singleton service
// https://angular.io/guide/singleton-services
import {CanvasService} from './map/canvas/canvas.service';
import {LayersConfigService} from './map/layers/layers.config.service';
import {ToolsService} from './map/tools/tools.service';
import { ToolsComponent } from './map/tools/tools.component';
import { LegendComponent } from './map/legend/legend.component';
import { SplitToolComponent } from './map/tools/split-tool/split-tool.component';
import { MergeToolComponent } from './map/tools/merge-tool/merge-tool.component';
import { FeatureService } from './service/feature.service';
import { NotificationService } from './service/notification.service';
import { FetureInfoToolComponent } from './map/tools/fetureinfo-tool/fetureinfo-tool.component';
import { PropertyRegistrationComponent } from './map/tools/property-registration/property-registration.component';
import { QuicksearchToolComponent } from './map/tools/quicksearch-tool/quicksearch-tool.component';
import { ToastrModule } from 'ngx-toastr';
import { PropertysearchToolComponent } from './map/tools/propertysearch-tool/propertysearch-tool.component';
import { AttributeSearchToolComponent } from './map/tools/attribute-search-tool/attribute-search-tool.component';
import { FotosfachadaManagerComponent } from './map/tools/fotosfachada-manager/fotosfachada-manager.component';
import { FotosfachadaEditComponent } from './map/tools/fotosfachada-manager/fotosfachada-edit/fotosfachada-edit.component';
import { PdfprintingComponent } from './map/tools/pdfprinting/pdfprinting.component';
import { DownloadToolComponent } from './map/tools/download-tool/download-tool.component';
import { EditLayerToolComponent } from './map/tools/edit-layer-tool/edit-layer-tool.component';
import { Photo360LayerToolComponent } from './map/tools/photo360-layer-tool/photo360-layer-tool.component';
import { EditAttributeLayerToolComponent } from './map/tools/edit-attribute-layer-tool/edit-attribute-layer-tool.component';
import { EditionService } from './service/edition.service';
import { EditStyleLayerToolComponent } from './map/tools/edit-style-layer-tool/edit-style-layer-tool.component';
import { SecurityManagementToolComponent } from './map/tools/security-management-tool/security-management-tool.component';
import { StreetSearchToolComponent } from './map/tools/street-search-tool/street-search-tool.component'
import { EditStreetAttributesComponent } from './map/tools/street-search-tool/edit-attribute-street/edit-street-attributes.component'
import { ExportSinterToolComponent } from './map/tools/export-sinter-tool/export-sinter-tool.component'
import { AuthenticationComponent } from './authentication/authentication.component';
import { LightboxModule } from 'ngx-lightbox';
import { SafePipe } from './util/safe.pipe';
import { FichaPreviewComponent } from './map/tools/property-registration/ficha-preview/ficha-preview.component';
import { FichaLinkComponent } from './map/tools/property-registration/ficha-link/ficha-link.component';
import { FichaUploadComponent } from './map/tools/property-registration/ficha-upload/ficha-upload.component';
import { FotoFachadaPreviewComponent } from './map/tools/property-registration/foto-fachada-preview/foto-fachada-preview.component';
import { VersionComponent } from './version/version.component';
import { ContactComponent } from './contact/contact.component';
import { EditEdificacoesToolComponent } from './map/tools/edit-edificacoes-tool/edit-edificacoes-tool.component';
import { GenericConfirmationDialogComponent } from './map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { AddColetaPGVToolComponent } from './map/tools/coleta-pgv-tool/add-coleta-pgv-tool/add-coleta-pgv-tool.component';
import { ManageColetaPGVToolComponent } from './map/tools/coleta-pgv-tool/manage-coleta-pgv-tool/manage-coleta-pgv-tool.component';
import { EditColetaPGVToolComponent } from './map/tools/coleta-pgv-tool/edit-coleta-pgv-tool/edit-coleta-pgv-tool.component';
import { LayersCruzamentoConfigComponent } from './map/tools/certidoes-config-tool/layers-cruzamento-config/layers-cruzamento-config.component';
import { GenerateCertidoesComponent } from './map/tools/certidoes-tool/generate-certidoes.component';
import { GenerateCertidaoUsoComponent } from './map/tools/certidoes-tool/certidao-uso/generate-certidao-uso.component';
import { GenerateCertidaoEmplacamentoComponent } from './map/tools/certidoes-tool/certidao-emplacamento/generate-certidao-emplacamento.component';
import { GenerateCertidaoUnificacaoComponent } from './map/tools/certidoes-tool/certidao-unificacao/generate-certidao-unificacao.component';
import { GenerateCertidaoDesdobroComponent } from './map/tools/certidoes-tool/certidao-desdobro/generate-certidao-desdobro.component';
import { GenerateCertidaoAreasEDatasComponent } from './map/tools/certidoes-tool/certidao-areas-e-datas/generate-certidao-areas-e-datas.component';
import { GenerateCertidaoMedidasComponent } from './map/tools/certidoes-tool/certidao-medidas/generate-certidao-medidas.component';
import { GenerateRelatorioGeralImovelComponent } from './map/tools/certidoes-tool/generate-relatorio-geral-imovel/generate-relatorio-geral-imovel.component';
import { EditHistoricoComponent } from './map/tools/property-registration/edit-historico/edit-historico.component';
import { SetupImoveisLinkToolComponent } from './map/tools/setup-imoveis-link-tool/setup-imoveis-link-tool.component';
import { SearchCoordComponent } from './map/tools/search-coord-tool/search-coord-tool.component';
import { LayersConfigComponent } from './map/tools/app-config-tool/layers-config.component';
import { AddGeomByCoordsEditionLayerToolComponent } from './map/tools/add-geom-by-coords-edition-layer-tool/add-geom-by-coords-edition-layer-tool.component';
import { ImportLayerComponent } from './map/tools/app-config-tool/import-layer/import-layer.component';
import { SettlementComponent } from './map/tools/settlement-tools/settlement.component';
import { DocumentUploadComponent } from './map/dialog/document-upload-dialog/document-upload.component';
import { DocumentPreviewComponent } from './map/dialog/document-preview-dialog/document-preview.component';
import { ManageSettlementToolComponent } from './map/tools/manage-settlement-tool/manage-settlement-tool.component';
import { GeneratingReportComponent } from './map/tools/generic-feature-report-tool/reports/generating-report/generating-report.component'
import { ManageAnnotationToolComponent } from './map/tools/annotation-tool/manage-annotation-tool/manage-annotation-tool.component'
import { AnnotationNameComponent } from './map/tools/annotation-tool/annotation-name/annotation-name.component'
import { SettlementPropertyComponent } from './map/tools/settlement-property-tools/settlement-property.component';
import { EditCadastroSocialComponent } from './map/tools/settlement-property-tools/cadastro-social/edit-cadastro-social.component';


import { NgxMaskModule, IConfig } from 'ngx-mask'
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgJsonEditorModule } from 'ang-jsoneditor';

registerLocaleData(localePt, 'pt');

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [
    SafePipe,
    AppComponent ,
    CanvasComponent,
    LayersComponent,
    MapComponent,
    ToolsComponent,
    LegendComponent,
    FetureInfoToolComponent,
    SplitToolComponent,
    MergeToolComponent,
    PropertyRegistrationComponent,
    DialogComponent,
    QuicksearchToolComponent,
    PropertysearchToolComponent,
    PdfprintingComponent,
    DownloadToolComponent,
    EditLayerToolComponent,
    EditAttributeLayerToolComponent,
    Photo360LayerToolComponent,
    EditStyleLayerToolComponent,
    AuthenticationComponent,
    SecurityManagementToolComponent,
    StreetSearchToolComponent,
    FichaPreviewComponent,
    FichaLinkComponent,
    FichaUploadComponent,
    VersionComponent,
    ContactComponent,
    EditEdificacoesToolComponent,
    GenericConfirmationDialogComponent,
    AddColetaPGVToolComponent,
    ManageColetaPGVToolComponent,
    EditColetaPGVToolComponent,
    LayersCruzamentoConfigComponent,
    GenerateCertidaoUsoComponent,
    EditHistoricoComponent,
    GenerateCertidaoEmplacamentoComponent,
    GenerateCertidoesComponent,
    GenerateCertidaoUnificacaoComponent,
    GenerateCertidaoDesdobroComponent,
    GenerateCertidaoAreasEDatasComponent,
    GenerateCertidaoMedidasComponent,
    EditStreetAttributesComponent,
    FotoFachadaPreviewComponent,
    FotosfachadaManagerComponent,
    FotosfachadaEditComponent,
    SetupImoveisLinkToolComponent,
    SearchCoordComponent,
    LayersConfigComponent,
    AttributeSearchToolComponent, 
    GenerateRelatorioGeralImovelComponent,
    AddGeomByCoordsEditionLayerToolComponent,
    ImportLayerComponent,
    SettlementComponent,
    DocumentUploadComponent,
    DocumentPreviewComponent,
    ManageSettlementToolComponent,
    GeneratingReportComponent,
    ManageAnnotationToolComponent,
    AnnotationNameComponent,
    SettlementPropertyComponent,
    EditCadastroSocialComponent,
    ExportSinterToolComponent
  ],
  exports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    SafePipe
  ],
  imports: [
    ApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    LeafletModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatNativeDateModule,
    HttpClientModule,
    CustomMaterialModule,
    FormsModule,
    NgImageSliderModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgJsonEditorModule,    
    ToastrModule.forRoot(),
    LightboxModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    CanvasService,
    LayersConfigService,
    ToolsService,
    FeatureService,
    NotificationService,
    EditionService,
    { provide: BASE_PATH, useValue: environment.BACKEND_API_BASE_PATH },
    { provide: MatPaginatorIntl, useClass: MatPaginatorLabel},
    { provide: LOCALE_ID, useValue: 'pt-BR' }    
  ],
  bootstrap: [AppComponent],
  entryComponents: [LayersComponent,DialogComponent]
})
export class AppModule { }
