<mat-accordion *ngIf="layer">
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>{{layer.getName()}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-radio-group aria-labelledby="example-radio-group-label" [(ngModel)]="currentStyle">
                    <table mat-table [dataSource]="styles" class="mat-elevation-z8">
                        <ng-container matColumnDef="enabled">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let style">
                                <mat-radio-button class="example-radio-button" [value]="style">
                              </mat-radio-button>
                            </td>
                          </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let style" class="name-column"> {{style.name}} </td>
                          </ng-container>
                          <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef> Descrição </th>
                            <td mat-cell *matCellDef="let style" class="title-column"> {{style.title}} </td>
                            
                          </ng-container>


                          <ng-container matColumnDef="legend">
                            <th mat-header-cell *matHeaderCellDef> Legenda </th>
                            <td mat-cell *matCellDef="let style">
                                
                                <img src="{{style.legendURL}}">
                            
                            </td>
                            
                          </ng-container>











                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
              </mat-radio-group>                
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>


<div class="buttons-div">
    <!-- <div class="save-button">
        <button mat-raised-button color="primary" (click)="save()">Salvar</button>

    </div> -->
    <div class="cancel-button">
        <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
    </div>
    <div class="apply-button">
        <button mat-raised-button color="primary" (click)="save()">Aplicar</button>

    </div>
</div>



