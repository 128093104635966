<div id="body">
    <div id="left-div">
        <mat-card id="progresso">
            <div id="passos-div">
                <mat-card *ngFor="let step of stepsController.getSteps()" [ngClass]="step ==  stepsController.getCurrentStep() ? 'passo-selecionado' : ''">Passo {{step.index}}</mat-card>
            </div>
            <mat-progress-bar id="progress-bar" mode="determinate" [value]="progressBarPercent">
            </mat-progress-bar>
        </mat-card>
        <mat-card id="map-card">
            <div id="ol_map" class="map-container"></div>
        </mat-card>
    </div>
    <div id="right-div">
        <mat-card id="passos-descricao-card">
            <mat-card-title>{{stepsController.getCurrentStep().getTitle()}}</mat-card-title>
            <mat-card-content>{{stepsController.getCurrentStep().getDescription()}}</mat-card-content>
        </mat-card>

        <mat-card id="tools-card" *ngIf="isCardVisible('tools-card')">
            <div>
            </div>            
        </mat-card>

        <mat-card id="select-operation-card" *ngIf="isCardVisible('select-operation-card')">
            
            <mat-card-title>Operação:</mat-card-title>
            <mat-card-content>
            <mat-radio-group aria-label="Operação:" >
                <mat-radio-button value="anexacao" [checked]="'anexacao'=== selectedOperation" (change)="onOperationChanged($event)">Unificação</mat-radio-button>
                <mat-radio-button value="desdobramento" [checked]="'desdobramento' === selectedOperation" (change)="onOperationChanged($event)">Desdobramento</mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
        <mat-card-footer>   </mat-card-footer>
        </mat-card>        

        <mat-card id="selected-operation-card" *ngIf="isCardVisible('selected-operation-card')">
            <mat-card-title>Operação selecionada:</mat-card-title>
            <mat-card-content>
                <span *ngIf="selectedOperation=='anexacao'">Unificação</span>
                <span *ngIf="selectedOperation=='desdobramento'">Desdobramento</span>
        </mat-card-content>
        <mat-card-footer>   </mat-card-footer>
        
        </mat-card>

        <!-- ANEXACAO -->

        <mat-card id="anexacao-selected-inscricao-card" *ngIf="isCardVisible('anexacao-selected-inscricoes-card')">
    
            <mat-card-title >Inscrições a serem Anexadas (desativadas):
                <button type="button" title="Buscar Inscrições." (click)="enableEditionInteraction(getFeatureInfoInteractionId, element)" mat-raised-button color="primary">
                    <mat-icon>
                        ads_click
                    </mat-icon> Seleção por apontamento...
                </button>
            </mat-card-title>
            <mat-card-content>
                <mat-radio-group aria-label="Inscrições a serem Anexadas:" >

                    <table *ngIf="anexacaoSelectedImoveis.length>0" mat-table id="anexacaoSelectedImoveisTable" [dataSource]="anexacaoSelectedImoveis" class="mat-elevation-z8">
                      
                      <ng-container matColumnDef="anexacao-selected-imoveis-radio">
                          <th mat-header-cell *matHeaderCellDef> </th>
                          <td mat-cell *matCellDef="let element"> <mat-radio-button [value]="element" [checked]="element === selectedEditionFeature" (change)="onImovelChange($event)"></mat-radio-button></td>
                       </ng-container>
                       
         
                        <ng-container matColumnDef="ic">
                          <th mat-header-cell *matHeaderCellDef> Inscrição </th>
                          <td mat-cell *matCellDef="let element"> 
                            {{imovelToInscricaoCompleta(element.getImovel())}}
                          </td>
                        </ng-container>
      
                        <ng-container matColumnDef="endereco">
                          <th mat-header-cell *matHeaderCellDef> Endereço </th>
                          
                          <td mat-cell *matCellDef="let element"> 
                            {{element.getImovel()['endereco_imovel']}} - {{element.getImovel()['numero_imovel']}}                                                   
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="acoes">
                            <th mat-header-cell *matHeaderCellDef> Ações </th>
                            
                            <td mat-cell *matCellDef="let element"> 
                              <mat-icon class="imovel-action" (click)="enableEditionInteraction(deleteInterectionId, element)">delete</mat-icon>
                            </td>
                        </ng-container>          
     
                        <tr mat-header-row *matHeaderRowDef="anexacaoSelectedImoveisTableRowsColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: anexacaoSelectedImoveisTableRowsColumns; let i = index"></tr>
                    </table>
      
                  </mat-radio-group>
                
            </mat-card-content>
        <mat-card-footer>
        </mat-card-footer>
        </mat-card>



        <mat-card id="anexacao-new-inscricao-card" *ngIf="isCardVisible('anexacao-new-inscricao-card')">
    
            <mat-card-title>Inscrição nova (ativada): 
                <button type="button" title="Buscar Inscrições." (click)="searchInscricoes()" mat-raised-button color="primary">
                    <span class="material-icons">add</span> Buscar Inscrição...
                </button>
            </mat-card-title>

            <mat-card-content>
                <mat-radio-group aria-label="Inscrição Nova:" >

                    <table *ngIf="anexacaoNewLinkImoveis.length>0" mat-table id="anexacaoNewLinkImoveisTable" [dataSource]="anexacaoNewLinkImoveis" class="mat-elevation-z8">
                      
                      <ng-container matColumnDef="anexacao-new-imoveis-radio">
                          <th mat-header-cell *matHeaderCellDef> </th>
                          <td mat-cell *matCellDef="let element"> <mat-radio-button [value]="element" [checked]="element === selectedEditionFeature" (change)="onImovelChange($event)"></mat-radio-button></td>
                       </ng-container>
                       
                       <ng-container matColumnDef="index">
                          <th mat-header-cell *matHeaderCellDef > Imóvel </th>
                          <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
                       </ng-container>                                                
         
                        <ng-container matColumnDef="ic">
                          <th mat-header-cell *matHeaderCellDef> Inscrição </th>
                          <td mat-cell *matCellDef="let element"> 
                            {{imovelToInscricaoCompleta(element.getImovel())}}
                          </td>
                        </ng-container>
      
                        <ng-container matColumnDef="endereco">
                          <th mat-header-cell *matHeaderCellDef> Endereço </th>
                          
                          <td mat-cell *matCellDef="let element"> 
                            {{element.getImovel()['endereco_imovel']}} - {{element.getImovel()['numero_imovel']}}                                                   
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="acoes">
                            <th mat-header-cell *matHeaderCellDef> Ações </th>
                            
                            <td mat-cell *matCellDef="let element"> 
                              <mat-icon *ngIf="element.getFeature()==null" class="imovel-action" (click)="enableEditionInteraction(addInterectionId, element)">add</mat-icon>      
                              <mat-icon class="imovel-action" (click)="enableEditionInteraction(deleteInterectionId, element)">delete</mat-icon>
                            </td>
                        </ng-container>          
     
                        <tr mat-header-row *matHeaderRowDef="anexacaoNewLinkImoveisTableRowsColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: anexacaoNewLinkImoveisTableRowsColumns; let i = index"></tr>
                    </table>
      
                  </mat-radio-group>
                
            </mat-card-content>
        <mat-card-footer>
        </mat-card-footer>
        </mat-card>











        <!-- DESDOBRO -->

        <mat-card id="desdobro-selected-inscricao-card" *ngIf="isCardVisible('desdobro-selected-inscricao-card')">
    
            <mat-card-title>Inscrição a ser Desdobrada (inativada):
                
            </mat-card-title>
            <mat-card-content>
                <p *ngIf="currentCadImob">{{currentInscricao}} - {{currentCadImob.imovel['endereco_imovel']}} - {{currentCadImob.imovel['numero_imovel']}}</p>
            </mat-card-content>
        <mat-card-footer>
        </mat-card-footer>
        </mat-card>

        <mat-card id="desdobro-new-inscricoes-card" *ngIf="isCardVisible('desdobro-new-inscricoes-card')">
            <mat-card-title>Inscrição novas (ativadas): 
                <button type="button" title="Buscar Inscrições." (click)="searchInscricoes()" mat-raised-button color="primary">
                    <span class="material-icons">add</span> Buscar Inscrições...
                </button>
            </mat-card-title>
            <mat-card-content>

                <mat-radio-group aria-label="Inscrições novas:" >

                    <table *ngIf="desdobroNewImoveisLink.length>0" mat-table id="desdobroNewImoveisTable" [dataSource]="desdobroNewImoveisLink" class="mat-elevation-z8">
                      
                      <ng-container matColumnDef="desdobro-new-imoveis-radio">
                          <th mat-header-cell *matHeaderCellDef> </th>
                          <td mat-cell *matCellDef="let element"> <mat-radio-button [value]="element" [checked]="element === selectedEditionFeature" (change)="onImovelChange($event)"></mat-radio-button></td>
                       </ng-container>
                       
                       <ng-container matColumnDef="index">
                          <th mat-header-cell *matHeaderCellDef > Imóvel </th>
                          <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
                       </ng-container>                                                
         
                        <ng-container matColumnDef="ic">
                          <th mat-header-cell *matHeaderCellDef> Inscrição </th>
                          <td mat-cell *matCellDef="let element"> 
                            {{imovelToInscricaoCompleta(element.getImovel())}}
                          </td>
                        </ng-container>
      
                        <ng-container matColumnDef="endereco">
                          <th mat-header-cell *matHeaderCellDef> Endereço </th>
                          
                          <td mat-cell *matCellDef="let element"> 
                            {{element.getImovel()['endereco_imovel']}} - {{element.getImovel()['numero_imovel']}}                                                   
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="acoes">
                            <th mat-header-cell *matHeaderCellDef> Ações </th>
                            
                            <td mat-cell *matCellDef="let element"> 
                              <mat-icon *ngIf="element.getFeature()==null" class="imovel-action" (click)="enableEditionInteraction(addInterectionId, element)">add</mat-icon>                              
                              <mat-icon class="imovel-action" (click)="enableEditionInteraction(deleteInterectionId, element)">delete</mat-icon>
                            </td>
                        </ng-container>                        

                        
      
     
                        <tr mat-header-row *matHeaderRowDef="desdobroNewImoveisTableRowsColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: desdobroNewImoveisTableRowsColumns; let i = index"></tr>
                    </table>
      
                  </mat-radio-group>

            </mat-card-content>
        <mat-card-footer>   

        </mat-card-footer>
        </mat-card>

        <mat-card id="botoes-card"  *ngIf="isCardVisible('botoes-card')">
            <div class="botoes-div">
                <button mat-raised-button color="primary" class="voltar-recuo" (click)="back()">Voltar</button>
                
                <button *ngIf="!stepsController.isLastStep()" mat-raised-button color="primary" class="avancar-recuo" (click)="next()">Avançar</button>
                <button *ngIf="stepsController.isLastStep()" mat-raised-button color="primary" class="avancar-recuo" (click)="finish()">Finalizar</button>
            </div>
        </mat-card>    
        
    </div>
</div>
