import { Injectable } from '@angular/core';
import { Cadastroimobiliario,
  DefaultService,
  Enderecos,
  Logradouros,
  Imoveis,
  Filter, 
  Fichas,
  Insertoperation,
  Deleteoperation,
  Updateoperation,
  Updateattributes,
  Authentication,
  Users,
  Usersgroup,
  Usergroup,
  Fotosfachada,
  Fichasresultado,
  Fichasfilter,
  Getfeatureattributes,
  Featureattributes,
  EditionoperationsInner,
  Certidoescamadasconfig,
  FotosfachadaInner,  
  Changepassword,    
  Assentamentosfilter,
  Relatoriogeraldoimovelconfig,
  Importlayerfile,
  Importlayerfileinfo,
  Importlayercheckinforesult,
  Geooperationarea,
  Geooperationcontains,
  Relatorioareairregularconfig,
  Annotation
  } from '@funcate/sigweb-cti-api';
import { Certidaoemplacamentoconfig } from '@funcate/sigweb-cti-api/model/certidaoemplacamentoconfig';
import { Copyadditionalimoveldata } from '@funcate/sigweb-cti-api/model/copyadditionalimoveldata';
import { Documentos } from '@funcate/sigweb-cti-api/model/documentos';
import { Historicoprocessoimovel } from '@funcate/sigweb-cti-api/model/historicoprocessoimovel';
import { Imoveisrelations } from '@funcate/sigweb-cti-api/model/imoveisrelations';
import { Newlayersconfig } from '@funcate/sigweb-cti-api/model/newlayersconfig';
import { Relatoriogeralfeicaoconfig } from '@funcate/sigweb-cti-api/model/relatoriogeralfeicaoconfig';
import { Resumorevisao2021 } from '@funcate/sigweb-cti-api/model/resumorevisao2021';


@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private apiGateway: DefaultService) { }
  
  cadImob:Cadastroimobiliario;

  public getCadImob(cadImobId:string): Promise<Cadastroimobiliario> 
  {
    return this.apiGateway.getCadImobByCode(cadImobId).toPromise();
  }

  public getImoveis(filter:Filter): Promise<Imoveis> {
    return this.apiGateway.getImoveis(filter).toPromise();
  }

  public getAssentamentos(filter:Assentamentosfilter): Promise<any> {
    return this.apiGateway.getAssentamentos(filter).toPromise();
  }

  public getEnderecos(): Promise<Enderecos> {
    return this.apiGateway.getEnderecos().toPromise();
  }

  public getLogradouros(): Promise<Logradouros> {
    return this.apiGateway.getLogradouros().toPromise();
  }

  public associateGeo(inscricao:string,featureId:string): Promise<Enderecos> {
    return this.apiGateway.associateInscricaoAndFeature(inscricao,featureId).toPromise();
  }

  public deassociateGeo(inscricao:string,featureId:string): Promise<any> {
    return this.apiGateway.deassociateInscricaoAndFeature(inscricao,featureId).toPromise()
  }

  public getFichas(inscricao: string): Promise<Fichas> {
    return this.apiGateway.getFichasByInscricao(inscricao).toPromise();
  }
  public getFichasByFiltro(fichasfilter: Fichasfilter): Promise<Fichasresultado> {
    return this.apiGateway.getFichasByFilter(fichasfilter).toPromise();
  }
  public setFichasForInscricao(fichas: Fichasresultado, inscricao: string): Promise<Fichasresultado> {
    return this.apiGateway.setFichasForInscricao(fichas, inscricao).toPromise();
  }
  public insertGeom(insertOperation: Insertoperation): Promise<any> {
    return this.apiGateway.editionInsertGeom(insertOperation).toPromise();
  }
  public deleteGeom(deleteOperation: Deleteoperation): Promise<any> {
    return this.apiGateway.editionDeleteGeom(deleteOperation).toPromise();
  }
  public updateGeom(updateOperation: Updateoperation): Promise<any> {
    return this.apiGateway.editionUpdateGeom(updateOperation).toPromise();
  }

  public updateAttributes(updateattributes: Updateattributes): Promise<any> {
    return this.apiGateway.editionUpdateAttributes(updateattributes).toPromise();
  }

  public getFeatureAttributes(Getfeatureattributes: Getfeatureattributes): Promise<Featureattributes> {
    return this.apiGateway.editionGetFeatureAttributes(Getfeatureattributes).toPromise();
  }

  public getFotosFachada(inscricao: string): Promise<Fotosfachada> {
    return this.apiGateway.getFotosFachadaByInscricao(inscricao).toPromise();
  }

  public getFotoFachada(id: string): Promise<Blob> {
    return this.apiGateway.getFotoFachada(id).toPromise();
  }

  public deleteFotoFachada(fotosFachadas: Array<FotosfachadaInner>): Promise<any> {
    return this.apiGateway.deleteFotoFachada(fotosFachadas).toPromise();
  }

  public addFotoFachada(inscricoes: string, nomeArquivo: string, dataCaptura: string, arquivo: Blob): Promise<any> {
    return this.apiGateway.addFotoFachadaForm(inscricoes, nomeArquivo, dataCaptura, arquivo).toPromise();
  }

  public editFotoFachada(fotosFachadas: Array<FotosfachadaInner>): Promise<any> 
  {
    return  this.apiGateway.editFotoFachada(fotosFachadas).toPromise();
  }

  public getHistoricoProcessoImovel(inscricao: string): Promise<Historicoprocessoimovel> {
    return this.apiGateway.getHistoricoProcessoImovel(inscricao).toPromise();
  }

  /*
  Access Control
  */

  public authenticate(authentication: Authentication): Promise<any> {
    return this.apiGateway.authenticate(authentication).toPromise();
  }

  public getAllUsers(): Promise<Users> {
    return this.apiGateway.getAllAppUsers().toPromise();
  }

  public getUsersGroups(): Promise<Usersgroup> {
    return this.apiGateway.getAllUserGroup().toPromise()
  }


  public setUserGroup(userGroup: Usergroup): Promise<any> {
    return this.apiGateway.setUserGroup(userGroup).toPromise()
  }

  public uploadFicha(inscricoes: string, nomeArquivo: string, arquivo: Blob): Promise<any> {
    return this.apiGateway.uploadFichaForm(inscricoes, nomeArquivo, arquivo).toPromise();
  }

  public executeEditionOperations(editionOperations: Array<EditionoperationsInner>) : Promise<any> 
  {
    return this.apiGateway.editionFeaturesExecuteOperations(editionOperations).toPromise();
  }

  public getApiVersion(): Promise<any> {
    return this.apiGateway.getVersion().toPromise();
  }

  public setAuthToken(token: string)
  {
    this.apiGateway.configuration.accessToken=token;
  }
  public getLayers(): Promise<any>
  {
    return this.apiGateway.getLayers().toPromise()
  }

  public getCertidoesLayersOrder() : Promise<any> 
  {
    return this.apiGateway.getCertidoesLayersOrder().toPromise();
  }

  public setCertidoesLayersOrder(certidoescamadasconfig: Certidoescamadasconfig) : Promise<any> 
  {
    return this.apiGateway.setCertidoesLayersOrder(certidoescamadasconfig).toPromise();
  }

  public addHistoricoProcessoImovel(historicoprocessoimovel: Historicoprocessoimovel) : Promise<any> 
  {
    return this.apiGateway.addHistoricoProcessoImovel(historicoprocessoimovel).toPromise();
  }

  public editHistoricoProcessoImovel(historicoprocessoimovel: Historicoprocessoimovel) : Promise<any> 
  {
    return this.apiGateway.editHistoricoProcessoImovel(historicoprocessoimovel).toPromise();
  }

  public deleteHistoricoProcessoImovel(id: string) : Promise<any> 
  {
    return this.apiGateway.deleteHistoricoProcessoImovel(id).toPromise();
  }

  public getCertidaoEmplacamento(certidaoemplacamentoconfig: Certidaoemplacamentoconfig) : Promise<Blob> 
  {
    return this.apiGateway.getCertidaoEmplacamento(certidaoemplacamentoconfig).toPromise();
  }

  public copyAdditionalImovelData(copyadditionalimoveldata: Copyadditionalimoveldata) : Promise<Blob> 
  {
    return this.apiGateway.copyAdditionalImovelData(copyadditionalimoveldata).toPromise();
  }


  public updateImoveisRelation(imoveisrelations: Imoveisrelations) : Promise<Imoveisrelations> 
  {
    return this.apiGateway.updateImoveisRelation(imoveisrelations).toPromise();
  }
  public getResumoRevisao2021(inscricao: string): Promise<Resumorevisao2021> {
    return this.apiGateway.getResumoRevisao2021(inscricao).toPromise();
  }

  public changePassword(changePassword: Changepassword): Promise<any> {
    return this.apiGateway.changePassword(changePassword).toPromise();
  }
  public setNewLayersConfig(newLayersConfig: Newlayersconfig): Promise<any> {
    return this.apiGateway.setNewLayersConfig(newLayersConfig).toPromise();
  }
  public getRelatorioGeralImovel(relatoriogeraldoimovelconfig: Relatoriogeraldoimovelconfig) : Promise<Blob> 
  {
    return this.apiGateway.getRelatorioGeralImovel(relatoriogeraldoimovelconfig).toPromise();
  }

  public uploadLayerFile(nomeArquivo: string , layerFileData: Blob) : Promise<Importlayerfileinfo> 
  {
    return this.apiGateway.uploadLayerFileForm(nomeArquivo, layerFileData).toPromise();
  }

  public uploadSldFile(layername: string, nomeArquivo: string , layerFileData: Blob) : Promise<any> 
  {
    return this.apiGateway.uploadSldFileForm(layername, nomeArquivo, layerFileData).toPromise();
  }

  public checkLayerImportInfo(layername: string) : Promise<Importlayercheckinforesult> 
  {
    return this.apiGateway.checkLayerImportInfo(layername).toPromise();
  }

  public deleteLayer(layername: string) : Promise<any> 
  {
    return this.apiGateway.deleteLayer(layername).toPromise();
  }

  public importLayer(layerInfo: Importlayerfileinfo) : Promise<Importlayerfileinfo> 
  {
    return this.apiGateway.importLayer(layerInfo).toPromise();
  }
  public getDocumentos(tipo: number, featureId: string) : Promise<Documentos> 
  {
    return this.apiGateway.getDocumentos(tipo, featureId).toPromise();
  }
  public getDocumentoById(id: string, extension: string) : Promise<Blob> 
  {
    return this.apiGateway.getDocumentoById(id, extension).toPromise();
  }
  public uploadDocumento(nomeArquivo: string, extensaoArquivo: string, tipo: string, dataInsercao: string, featureId: string, auxData: string, arquivo: Blob) : Promise<any> 
  {
    return this.apiGateway.uploadDocumentoForm(nomeArquivo, extensaoArquivo, tipo, dataInsercao, featureId, arquivo, auxData).toPromise();
  }
  public deleteDocumento(id: string) : Promise<any> 
  {
    return this.apiGateway.deleteDocumento(id).toPromise();
  }
  public calculateArea(areaOperation: Geooperationarea) : Promise<any> 
  {
    return this.apiGateway.geoOperationArea(areaOperation).toPromise();
  }
  public containsOperation(areaOperation: Geooperationcontains) : Promise<any> 
  {
    return this.apiGateway.geoOperationContains(areaOperation).toPromise();
  }
  public getRelatorioAreaIrregular(config: Relatorioareairregularconfig) : Promise<Blob> 
  {
    return this.apiGateway.getRelatorioAreaIrregular(config).toPromise();
  }
  public getRelatorioGeralFeicao(config: Relatoriogeralfeicaoconfig) : Promise<Blob> 
  {
    return this.apiGateway.getRelatorioGeralFeicao(config).toPromise();
  }
  public getAnnotation(userGroupId: string) : Promise<Annotation> 
  {
    return this.apiGateway.getAnnotation(userGroupId).toPromise();
  }

  public setAnnotation(annotation: Annotation) : Promise<Annotation> 
  {
    return this.apiGateway.setAnnotation(annotation).toPromise();
  }

  public exportSinter() : Promise<any> 
  {
    return this.apiGateway.exportSinter().toPromise();
  }


}
