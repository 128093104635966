import { Component, OnInit, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { CanvasService } from '../../canvas/canvas.service';
import { LayerConfig } from '../../layers/layers.config';

import { Style } from '../../vo/style';
import { ToolsService } from '../tools.service';

import { StyleService } from 'src/app/service/style.service';
import { EditStyleLayerTool } from './edit-style-layer-tool';
import { Layer } from 'leaflet';
import { CapabilitiesService } from 'src/app/service/capabilities.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-edit-style-layer-tool',
  templateUrl: './edit-style-layer-tool.component.html',
  styleUrls: ['./edit-style-layer-tool.component.css']
})

export class EditStyleLayerToolComponent implements OnInit 
{
  private tool:EditStyleLayerTool;
  private subscriptionTool:Subscription;
  public layer: LayerConfig;
  public stylesMap: Map<string, Style>;
  public styles: Style[];
  public currentStyle: Style;
  displayedColumns: string[] = ['enabled','name', 'title', 'legend'];  

  constructor(private toolsService: ToolsService,
    private canvasService:CanvasService,
    private styleService:StyleService ,
    private capabilitiesService: CapabilitiesService,
    private toastService: ToastService) 
    {
      this.stylesMap = new Map<string, Style>();
      this.styles = [];
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:EditStyleLayerTool) => {
        this.tool=tool;
        this.update();
      },()=> {
      }
    );
   }

  ngOnInit(): void {
  }

  private update() 
  {
    if (this.tool) 
    {
      this.layer = this.toolsService.getToolParameter(this.tool, "layer");
      this.canvasService.getMap().eachLayer((l) => {
        if (l["wmsParams"])
        {

          if (l["id"] == this.layer.getId())
          {
            this.loadStyleForLayer(l);
          }
        }
      });
      

    }

  }

  public close()
  {
    this.tool.closeDialog();
  }

  public loadStyleForLayer(layer: Layer) 
  {
      this.capabilitiesService.getCapabilitiesforLayer(layer).then(capabilities => {

          if(capabilities)
          {
            try
            {
              let styles = this.styleService.getStylesFromCapabilitesObject(capabilities);
              
              if(!this.layer.getStyle())
              {
                //Selecting first style on capabilities if there is no style configured on layer yet
                this.layer.setStyle(styles[0].id);
              }

              styles.forEach(style => {
                if(style.id==this.layer.getStyle())
                {
                  style.enabled =true;
                  this.currentStyle=style;
                }
                else
                {
                  style.enabled =false;
                }
                this.stylesMap.set(style.id, style);
              });
              this.styles=[];
              this.stylesMap.forEach(style => {
                this.styles.push(style);
              });

            }catch(err)
            {
              this.stylesMap = new Map<string, Style>();
              this.styles = [];
              this.toastService.error("Problema ao obter estilos da camada solicitada.", "Erro");
              console.log("Problema ao obter estilos da camada via capabilities.");
            }
            
          }
      });
  
  }
  public apply()
  {
    this.layer.setStyle(this.currentStyle.id);
    this.layer.setStyleTitle(this.currentStyle.name);
    this.canvasService.redrawLayer(this.layer);
  }
  public save()
  {
    this.apply();
    this.tool.closeDialog();
  }

}
